import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { getCheckinUrl, getQrImageUrl } from "../../config/constants";
import styled from "styled-components";
import { getWindow } from "@utils/window";
import Seo from "@layouts/Seo";

/**
 * チェックインQRポスター印刷ページです。
 * URL例： http://localhost:8000/business/print_qr?id=4a210921-d3ce-4ca9-92ca-75056f355123
 */
const Page = () => {
  const { qrCodeId } = useQuery();
  const checkInUrl = qrCodeId && getCheckinUrl(qrCodeId);
  const qrImgUrl = checkInUrl && getQrImageUrl(checkInUrl, { size: 260 });
  return (
    <Main>
      <Seo title="" description="" noIndexNoFollow />
      <div className="img-wrapper">
        <StaticImage
          src="../../images/business/print_qr.png"
          alt="QR"
          width={700}
        />
        <div className="qr-img">
          {qrImgUrl && <img src={qrImgUrl} className="qr-img-img" />}
          <StaticImage
            src="../../images/qr_icon.png"
            alt="QRicon"
            width={100}
            className="qr-icon"
          />
        </div>
      </div>
    </Main>
  );
};

export default Page;

const useQuery = () => {
  const locationSearch = getWindow((w) => w.location.search);
  const defaults = React.useMemo(() => {
    const params = new URLSearchParams(locationSearch || "");
    const qrCodeId = params.get("id");

    return {
      qrCodeId,
    };
  }, [locationSearch]);
  return defaults;
};

const Main = styled.div`
  .img-wrapper {
    position: relative;
    width: 700px;
    .qr-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -54%);
      z-index: 2;
      .qr-img-img {
        width: 260px;
        height: 260px;
      }

      .qr-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        border-radius: 4px;
      }
    }
  }

  @media print {
    width: 100%;
  }
`;
